<template>
  <div class="user">
    <div class="search">
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-input type="text" placeholder="请输入内容" v-model="searchValue"></el-input>
      <el-button @click="handleReast">
        <span>重置</span>
      </el-button>
      <el-button type="primary" @click="handleSearch">
        <span>查询</span>
      </el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" border
      :header-cell-style="{ 'text-align': 'center', background: '#f2f2f2' }" :cell-style="{ 'text-align': 'center' }">
      <el-table-column label="用户ID" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="注册手机">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.presEntityMobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="证件姓名（机构名称）">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.presEntityName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="注册时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.status == 0 ? '禁用' : '启用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleInfo(scope.$index, scope.row)">详情</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">{{ scope.row.status == 0 ? '启用' : '禁用'
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog title="详情" :visible.sync="dialogTableVisible">
      <div class="userInfo">
        <div>用户ID：{{ nowInfo.id }}</div>
        <div>证件图片：
          <img :src="item" alt="" v-for="(item) in nowInfo.presEntityCertFile" :key="item">
        </div>
        <div>证件号码（机构代码）：{{ nowInfo.presEntityCode ? nowInfo.presEntityCode : '-' }}</div>
        <div>邮箱：{{ nowInfo.presEntityEmail ? nowInfo.presEntityEmail : '-' }}</div>
        <div>证件类型：{{ certType[nowInfo.presEntityType] }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [{
        value: 1,
        label: '用户ID'
      }, {
        value: 2,
        label: '注册手机'
      }, {
        value: 3,
        label: '会员名称'
      }],
      value: 1,
      tableData: [],
      currentPage: 1,
      total: 0,
      searchValue: '', // 搜索内容
      certType: {
        0: '个人身份证',
        1: '企业营业执照',
        2: '事业单位法人证书',
        3: '组织机构代码'
      },
      dialogTableVisible: false,
      nowInfo: {}
    }
  },
  methods: {
    handleInfo(index, row) {
      this.nowInfo = row
      this.dialogTableVisible = true
      console.log(this.nowInfo)
    },
    handleDelete(row) {
      this.$axiosPost('/userManager/disableOrActivate', { id: row.id }).then(res => {
        if (res.code == 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
        this.getList()
      })
    },
    // 重置
    handleReast() {
      this.value = 1
      this.searchValue = ''
      this.getList()
    },
    // 查询
    handleSearch() {
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getList()
    },
    // 会员列表
    getList() {
      let params = {
        current: this.currentPage,
        pageSize: 10,
      }
      // 根据value判断当前搜索
      switch (this.value) {
        case 1:
          params.id = this.searchValue
          break;
        case 2:
          params.presEntityMobile = this.searchValue
          break;
        case 3:
          params.presEntityName = this.searchValue
          break;
      }
      this.$axiosGet('userManager/queryPage', params).then(res => {
        console.log(res);
        res.data.records.forEach(element => {
          element.presEntityCertFile = JSON.parse(element.presEntityCertFile)
        });
        this.tableData = res.data.records
        this.total = res.data.total
      })
    }
  },
  created() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  .el-input {
    width: 200px;
    margin: 0 10px;
  }
}

.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.el-button {
  padding: 10px 20px;
}

.userInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  // width: 50%;
  margin-left: 60px;

  div {
    width: 50%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    img {
      width: 112px;
      height: 68px;
      background: #ccc;
      margin-right: 20px;
    }
  }
}
</style>
